import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-real-time-virtual-agent-settings',
  templateUrl: './real-time-virtual-agent-settings.component.html',
  styleUrls: ['./real-time-virtual-agent-settings.component.scss'],
})
export class RealTimeVirtualAgentSettingsComponent implements OnInit {
  form: FormGroup;
  virtualAgentIntegration = false;
  postConversationProjectValue: string;

  private readonly prefix = 'RTGVirtualAgent';
  private readonly endpoint = this.prefix + '.Endpoint';
  private readonly identityBaseEndpoint = this.prefix + '.IdentityBaseEndpoint';
  private readonly clientId = this.prefix + '.ClientId';
  private readonly clientSecret = this.prefix + '.ClientSecret';
  private readonly webChatId = this.prefix + '.WebChatId';
  private readonly postConversationProject = this.prefix + '.PostConversationProject';

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private featureService: FeatureService
  ) {
    this.virtualAgentIntegration = this.featureService.isEnabled(
      FeatureConstants.VirtualAgentIntegration
    );

    this.postConversationProjectValue = this.config.getSetting(this.postConversationProject);

    if (!this.postConversationProjectValue) {
      this.postConversationProjectValue = 'RTG-Integration';
    }

    this.form = this.fb.group({
      endpoint: this.fb.group({
        name: this.endpoint,
        value: [
          this.config.getSetting(this.endpoint),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      identityBaseEndpoint: this.fb.group({
        name: this.identityBaseEndpoint,
        value: [
          this.config.getSetting(this.identityBaseEndpoint),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      clientId: this.fb.group({
        name: this.clientId,
        value: [
          this.config.getSetting(this.clientId),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      clientSecret: this.fb.group({
        name: this.clientSecret,
        value: [
          this.config.getSetting(this.clientSecret),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      webChatId: this.fb.group({
        name: this.webChatId,
        value: [
          this.config.getSetting(this.webChatId),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      postConversationProject: this.fb.group({
        name: this.postConversationProject,
        value: [
          this.postConversationProjectValue,
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push(
      {
        settingName: this.form.get('endpoint').get('name').value,
        settingValue: this.form.get('endpoint').get('value').value,
      },
      {
        settingName: this.form.get('identityBaseEndpoint').get('name').value,
        settingValue: this.form.get('identityBaseEndpoint').get('value').value,
      },
      {
        settingName: this.form.get('webChatId').get('name').value,
        settingValue: this.form.get('webChatId').get('value').value.toString(),
      },
      {
        settingName: this.form.get('postConversationProject').get('name').value,
        settingValue: this.form.get('postConversationProject').get('value').value,
      }
    );

    if (this.form.get('clientSecret').get('value').value) {
      settings.push({
        settingName: this.form.get('clientSecret').get('name').value,
        settingValue: this.form.get('clientSecret').get('value').value,
      });
    }

    if (this.form.get('clientId').get('value').value) {
      settings.push({
        settingName: this.form.get('clientId').get('name').value,
        settingValue: this.form.get('clientId').get('value').value,
      });
    }

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
