import { isArray } from '@abp/ng.core';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { AttachedDataFilterDto } from 'src/core/models/administration/attached-data/attached-data-filter.dto';
import { AttachedDataLookupDto } from 'src/core/models/administration/attached-data/attached-data-lookup.dto';
import { AttachedDataMultipleFilterDto } from 'src/core/models/administration/attached-data/attached-data-multiple-filter.dto';
import { AttachedDataMultipleValueModel } from 'src/core/models/administration/attached-data/attached-data-multiple-value.model';
import { AttachedDataDto } from 'src/core/models/administration/attached-data/attached-data.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { AttachedDataService } from 'src/core/services/administration/attached-data.service';
import { CrudService } from 'src/core/services/crud/crud.service';

@Component({
  selector: 'ca-attached-data-multiple-selector',
  templateUrl: './attached-data-multiple-selector.component.html',
  styleUrls: ['./attached-data-multiple-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AttachedDataMultipleSelectorComponent),
    },
  ],
})
export class AttachedDataMultipleSelectorComponent implements OnInit, ControlValueAccessor {
  @Input()
  maxSelectedItems: number;

  selectedMultipleValueList: AttachedDataMultipleValueModel[] = [];
  multipleValueList: AttachedDataMultipleValueModel[] = [];
  totalCount: number;

  set value(val: AttachedDataMultipleFilterDto) {
    this._value = val;
    this.setValues(val);
  }

  get value(): AttachedDataMultipleFilterDto {
    return this._value;
  }

  private _value: AttachedDataMultipleFilterDto = { filters: [] };

  constructor(
    private service: CrudService,
    private attachedDataService: AttachedDataService,
    private operators: Operators
  ) {}

  ngOnInit(): void {
    this.service
      .get<AttachedDataDto>(AttachedDataDto, {
        maxResultCount: 9999,
        skipCount: 0,
        filters: [
          {
            field: 'visible',
            value: true,
            operator: this.operators.Equals,
          },
        ],
        sorters: [],
      })
      .pipe(take(1))
      .subscribe(response => {
        this.totalCount = response.totalCount;

        this.multipleValueList = response.items?.map(a => {
          return {
            attachedData: a,
            values: a.isMultipleChoice
              ? []
              : [
                  {
                    value: '',
                    deletable: true,
                  },
                ],
          } as AttachedDataMultipleValueModel;
        });

        this.setValues(this.value);
      });
  }

  writeValue(obj: any): void {
    obj = obj ? obj : ({ filters: [] } as AttachedDataMultipleFilterDto);
    this.value = obj;
    this.setValues(obj);
  }

  onChange(selection: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  applyChanges(): void {
    this.onChange(this.getAttachedDataMultipleFilters(this.selectedMultipleValueList));
  }

  clearSelection(): void {
    this.selectedMultipleValueList = [];
  }

  customSearchFn(term: string, item: AttachedDataMultipleValueModel): boolean {
    term = term.toLocaleLowerCase();
    return item.attachedData.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  onItemSelect(eventArgs): void {
    if (isArray(eventArgs)) {
      this.selectedMultipleValueList = eventArgs;
      eventArgs.forEach(a => {
        if (
          a.attachedData.isMultipleChoice &&
          (!a.attachedData.attachedDataLookups || a.attachedData.attachedDataLookups.length <= 0)
        ) {
          this.attachedDataService
            .getAttachedDataLookups(a.attachedData.id)
            .pipe(take(1))
            .subscribe(response => {
              a.attachedData.attachedDataLookups = response;
            });
        }
      });

      this.applyChanges();
    }
  }

  onDeleteClick(selection: AttachedDataMultipleValueModel): void {
    if (selection) {
      this.selectedMultipleValueList = this.selectedMultipleValueList.filter(
        x => x.attachedData.id !== selection.attachedData.id
      );
      this.applyChanges();
    }
  }

  isSelected(value: AttachedDataMultipleValueModel): boolean {
    var selected = this.selectedMultipleValueList.filter(
      x => x.attachedData.id === value.attachedData.id
    );
    if (selected.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  onItemValueChange(selectedValue: AttachedDataMultipleValueModel, eventArgs): void {
    if (selectedValue && selectedValue.values.length > 0) {
      selectedValue.values[0].value = eventArgs.target.value;

      this.applyChanges();
    }
  }

  isValueSelected(
    selectedValue: AttachedDataMultipleValueModel,
    value: AttachedDataLookupDto
  ): boolean {
    var selected = selectedValue.values.filter(x => x.value === value.value);
    if (selected.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  private getAttachedDataMultipleFilters(
    multipleValueList: AttachedDataMultipleValueModel[]
  ): AttachedDataMultipleFilterDto {
    const tempMultipleValueList = ObjectHelper.deepCopy(multipleValueList);

    var newList: AttachedDataFilterDto[] = [];

    tempMultipleValueList.forEach(ad => {
      if (ad?.values?.length > 0) {
        ad?.values?.forEach(adv => {
          newList.push({
            id: ad.attachedData.id,
            name: ad.attachedData.name,
            value: adv.value,
          } as AttachedDataFilterDto);
        });
      } else {
        newList.push({
          id: ad.attachedData.id,
          name: ad.attachedData.name,
          value: '',
        } as AttachedDataFilterDto);
      }
    });

    return { filters: newList } as AttachedDataMultipleFilterDto;
  }

  private getAttachedDataMultipleValueList(
    attachedDataMultipleFilterDto: AttachedDataMultipleFilterDto
  ): AttachedDataMultipleValueModel[] {
    var newList: AttachedDataMultipleValueModel[] = [];

    if (attachedDataMultipleFilterDto && attachedDataMultipleFilterDto.filters) {
      const tempValueList = ObjectHelper.deepCopy(attachedDataMultipleFilterDto.filters);

      var resultMap = (resultMap = tempValueList?.reduce((r, a) => {
        r[a.id] = r[a.id] || [];
        r[a.id].push(a);
        return r;
      }, new Map<number, AttachedDataFilterDto[]>()));

      Object.entries<AttachedDataFilterDto[]>(resultMap)?.forEach(([key, value]) => {
        const ad = this.multipleValueList.find(
          x => key === x.attachedData.id.toString()
        )?.attachedData;

        if (
          ad &&
          ad.isMultipleChoice &&
          (!ad.attachedDataLookups || ad.attachedDataLookups.length <= 0)
        ) {
          this.attachedDataService
            .getAttachedDataLookups(ad.id)
            .pipe(take(1))
            .subscribe(response => {
              ad.attachedDataLookups = response;

              const item = newList.find(x => x.attachedData.id === ad.id);

              if (item && ad.attachedDataLookups && ad.attachedDataLookups.length > 0) {
                item.values = ad.attachedDataLookups.filter(x =>
                  item.values.map(k => k.value).includes(x.value)
                );
              }
            });
        }

        if (ad) {
          value.forEach(f => {
            const selectedValue = newList.find(x => x.attachedData.id === ad.id);
            if (selectedValue) {
              selectedValue.values.push({
                value: f.value,
                deletable: true,
              } as AttachedDataLookupDto);
            } else {
              newList.push({
                attachedData: ad,
                values: [
                  {
                    value: f.value,
                    deletable: true,
                  } as AttachedDataLookupDto,
                ],
              } as AttachedDataMultipleValueModel);
            }
          });
        }
      });
    }

    return newList;
  }

  private setValues(values: AttachedDataMultipleFilterDto): void {
    if (values && values.filters.length === 0) {
      this.clearSelection();
    } else if (this.multipleValueList && this.multipleValueList.length > 0) {
      this.selectedMultipleValueList = this.getAttachedDataMultipleValueList(values);
    }
  }
}
